import {
  rand,
  randBoolean,
  randNumber,
  randPastDate,
  randTextRange
} from '@ngneat/falso';

import { enumValueFaker } from '@utils/faker-utils';

import {
  AddressingMode,
  BaudRate,
  ByteOrder,
  DataBits,
  Encoding,
  GridMeterConnectionStatusEnum,
  HealthStatusEnum,
  Parity,
  SerialGridMeter,
  StopBits,
  TcpGridMeter,
  Variant
} from '@omnis-pulse-types';

export class SiteGridMeterMockFactory {
  static createSerialGridMeter(
    data?: Partial<SerialGridMeter['serialProperties']>
  ): SerialGridMeter {
    return {
      healthStatus: { status: enumValueFaker(HealthStatusEnum) },
      type: randTextRange({ min: 10, max: 15 }),
      variant: Variant.SERIAL,
      modbus: 'modbus',
      connectionStatus: {
        timestamp: randPastDate().toISOString(),
        status: enumValueFaker(GridMeterConnectionStatusEnum)
      },
      serialProperties: {
        comPort: rand([randTextRange({ min: 10, max: 15 }), '']),
        echo: rand([randBoolean(), undefined]),
        baudRate: enumValueFaker(BaudRate),
        parity: enumValueFaker(Parity),
        stopBits: enumValueFaker(StopBits),
        dataBits: enumValueFaker(DataBits),
        encoding: enumValueFaker(Encoding),
        maxAge: rand([randNumber().toString(), '']),
        addressingMode: enumValueFaker(AddressingMode),
        loopDelay: rand([randNumber().toString(), '']),
        openDelay: rand([randNumber().toString(), '']),
        readRetries: '1',
        interDeviceWait: rand([randNumber().toString(), '']),
        readTimeThreshold: rand([randNumber().toString(), '']),
        rs485DelayAfterTxMicroseconds: rand([randNumber().toString(), '']),
        rs485DelayBeforeTxMicroseconds: rand([randNumber().toString(), '']),
        rs485Mode: rand([randBoolean(), undefined]),
        timeout: rand([randNumber().toString(), '']),
        rs485RxDuringTx: rand([randBoolean(), undefined]),
        unitId: randNumber().toString(),
        rs485EnableTermination: rand([randBoolean(), undefined]),
        writeRetries: rand([randNumber().toString(), '']),
        rs485TxEnableActiveHigh: rand([randBoolean(), undefined]),
        transactionDelay: rand([randNumber().toString(), '']),
        byteOrder: enumValueFaker(ByteOrder),
        ...data
      }
    };
  }

  static createTcpGridMeter(
    data?: Partial<TcpGridMeter['tcpProperties']>
  ): TcpGridMeter {
    return {
      healthStatus: { status: enumValueFaker(HealthStatusEnum) },
      type: randTextRange({ min: 10, max: 15 }),
      modbus: 'modbus',
      variant: Variant.TCP,
      connectionStatus: {
        status: enumValueFaker(GridMeterConnectionStatusEnum),
        timestamp: randPastDate().toISOString()
      },
      tcpProperties: {
        addressingMode: enumValueFaker(AddressingMode),
        loopDelay: rand([randNumber().toString(), '']),
        readRetries: rand([randNumber().toString(), '']),
        interDeviceWait: rand([randNumber().toString(), '']),
        unitId: rand([randNumber().toString(), '']),
        timeout: rand([randNumber().toString(), '']),
        readTimeThreshold: rand([randNumber().toString(), '']),
        writeRetries: rand([randNumber().toString(), '']),
        host: rand([randTextRange({ min: 10, max: 15 }), '']),
        port: rand([randNumber().toString(), '']),
        byteOrder: enumValueFaker(ByteOrder),
        reconnect: rand([randBoolean(), undefined]),
        lockTimeout: rand([randNumber().toString(), '']),
        ...data
      }
    };
  }
}
