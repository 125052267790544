import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UserContext } from '@context/UserContext';

import { Typography, useAlert } from '@destination/components';

import { UserOrganizationDetails } from '@omnis-pulse-types';

export interface IOrganizationRow {
  organization: UserOrganizationDetails;
  isLast: boolean;
  handleClose: () => void;
}

export const OrganizationRow = ({
  organization,
  isLast,
  handleClose
}: IOrganizationRow) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'general.organization_select'
  });
  const [isActive, setIsActive] = useState(organization.isActive);
  const { switchOrganization } = useContext(UserContext);
  const { notify } = useAlert();

  const onClick = async () => {
    if (organization.isActive) {
      handleClose();
      return;
    }
    try {
      await switchOrganization(organization.organizationOmnisId ?? '');
    } catch {
      notify({
        header: t('could_not_switch_organization'),
        variant: 'error'
      });
    }
  };

  return (
    <button
      data-testid={`organization-row-${organization.organizationOmnisId}`}
      className={isLast ? '' : 'border-b border-[#EBEBEB]'}
      onMouseEnter={() => {
        if (organization.isActive) return;
        setIsActive(true);
      }}
      onMouseLeave={() => {
        if (organization.isActive) return;
        setIsActive(false);
      }}
      onClick={onClick}
    >
      <div className="my-[2px] cursor-pointer rounded-[4px] px-1 pb-1 pt-[3px] text-left hover:bg-[#F5F5F5] hover:font-bold">
        <Typography
          className={`select-none ${organization.isActive && 'font-bold'}`}
        >
          {organization.name}
        </Typography>

        {isActive && organization.roles && (
          <Typography className="mt-1 !text-[12px] font-bold">
            {organization.roles.length === 0 && t('no_roles')}
            {organization.roles.length === 1 &&
              `${t('your_role')}: ${t(organization.roles[0].toLowerCase())}`}
            {organization.roles.length === 2 &&
              `${t('your_roles')}: ${t(organization.roles[0].toLowerCase())}, ${t(organization.roles[1].toLowerCase())}`}
            {organization.roles.length > 2 &&
              `${t('your_roles')}: ${t(organization.roles[0].toLowerCase())} ${t('x_more_roles', { count: organization.roles.length - 1 })}`}
          </Typography>
        )}
      </div>
    </button>
  );
};
