import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import useSite from '@api/sites/use-site';
import { SiteHeader } from '@components/sites/siteHeader/SiteHeader';
import { TabLabel } from '@components/TabLabel';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';

import { Tab } from '@destination/components';

export const siteTabs = [
  { label: 'navigation.site_overview', path: '' },
  { label: 'navigation.devices', path: '/devices' },
  { label: 'navigation.load_management', path: '/load-management' },
  { label: 'navigation.logs', path: '/logs' },
  { label: 'navigation.insights', path: '/insights' }
];

export const Site = () => {
  const { id } = useParams() as { id: string };
  const basePath = `/sites/${id}`;
  const { t } = usePortalTranslation('sites');
  const { site, isLoading: siteLoading } = useSite(id);
  const { deviceData, isLoading: devicesLoading } = useSiteDevicesContext();

  const filteredTabs = siteTabs.filter(tab => {
    if (
      tab.label === 'navigation.load_management' ||
      tab.label === 'navigation.insights'
    ) {
      return !deviceData.hasNoDevices;
    }
    return true;
  });

  if (siteLoading || devicesLoading) return null;

  return (
    <>
      <TabLabel label={site?.label ?? ''} />
      <SiteHeader site={site} isLoading={siteLoading} />
      <nav className="tw-inline-flex gap-4">
        {filteredTabs.map(tab => (
          <NavLink
            key={tab.label}
            to={`${basePath}${tab.path}`}
            end={tab.path !== '/devices'}
          >
            {({ isActive }) => (
              <Tab data-testid={`tab-${tab.label}`} isActive={isActive}>
                {t(tab.label, { titleize: true })}
              </Tab>
            )}
          </NavLink>
        ))}
      </nav>
      <section className="mt-6 flex grow flex-col">
        <Outlet />
      </section>
    </>
  );
};
