import { usePortalTranslation } from '@hooks/use-portal-translation';

import { useUploadContext } from '@context/hmi/UploadContext';

import { Checkbox, Table, TableHeadCell } from '@destination/components';

import { HMICustomizationApplyToTableRow } from './HMICustomizationApplyToTableRow';

interface IHMICustomizationApplyToTable {
  chargers: {
    id: string;
    name: string;
    serialNumber: string;
    model: string;
  }[];
}

export const HMICustomizationApplyToTable = ({
  chargers
}: IHMICustomizationApplyToTable) => {
  const { t } = usePortalTranslation();
  const { selected, setSelected, isProcessing } = useUploadContext();

  const handleToggle = (id: string) => {
    setSelected(previousValue => {
      const newSelected = new Set(previousValue);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleToggleAll = () => {
    if (selected.size === chargers.length) {
      setSelected(new Set());
    } else {
      setSelected(new Set(chargers.map(charger => charger.id)));
    }
  };

  return (
    <Table className="w-full table-fixed border-separate border-spacing-y-0">
      <thead className="sticky top-0 z-10 bg-white">
        <tr className="leading-[0px]">
          <TableHeadCell className="text-nowrap !pb-2 !pl-3">
            <div
              className={`flex gap-2 [&>input]:!border-[1px] ${chargers.length !== selected.size && '[&>input]:!bg-[#F5F5F5]'}`}
            >
              <Checkbox
                label={undefined}
                checked={chargers.length === selected.size}
                disabled={isProcessing}
                onChange={handleToggleAll}
              />
              {`${t('literals.charger')} (${chargers.length})`}
            </div>
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.serial_number')}
          </TableHeadCell>
          <TableHeadCell className="text-nowrap">
            {t('literals.model')}
          </TableHeadCell>
        </tr>
      </thead>
      <tbody>
        {chargers.map((charger, index) => (
          <tr
            key={charger.id}
            className={index % 2 !== 0 ? 'tw-bg-[#FAFAFA]' : ''}
          >
            <HMICustomizationApplyToTableRow
              name={charger.name}
              serial={charger.serialNumber}
              model={charger.model}
              checked={selected.has(charger.id)}
              disabled={isProcessing}
              onToggle={() => handleToggle(charger.id)}
            />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
