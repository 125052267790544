import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { DEVICES_BY_SITE_ID_PATH, SITE_PATH } from '@api/paths';
import linkGatewayToSite from '@api/sites/link-gateway-to-site';
import { isNotEmpty } from '@utils/input-utils';

import { Dialog, Input, useAlert, useInput } from '@destination/components';

export interface ILinkGatewayToSiteForm {
  showDialog: boolean;
  onClose: () => void;
}

export const LinkGatewayToSiteForm = ({
  showDialog,
  onClose
}: ILinkGatewayToSiteForm) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams() as { id: string };

  const { t, t_generic } = usePortalTranslation('gateways');

  const { notify } = useAlert();

  const {
    value: serialNumber,
    valueChangeHandler: serialNumberChangeHandler,
    reset: serialNumberReset
  } = useInput(isNotEmpty, '');

  const handleClose = () => {
    serialNumberReset();
    onClose();
  };

  const handleConfirm = async () => {
    setIsUpdating(true);

    const { data, error } = await linkGatewayToSite(id, {
      serialNumber: serialNumber
    });

    if (axios.isAxiosError(error) && error.response?.status == 409) {
      notify({
        header: t('link_gateway_form.wrong_tgp'),
        variant: 'error',
        'data-testid': 'fail-message'
      });

      serialNumberReset();
    } else if (error) {
      notify({
        header: t('link_gateway_form.not_saved'),
        variant: 'error',
        'data-testid': 'fail-message'
      });

      serialNumberReset();
    } else {
      notify({
        header: t('link_gateway_form.saved'),
        variant: 'success',
        'data-testid': 'success-message'
      });

      await mutate(
        key => typeof key === 'string' && key.startsWith(`${SITE_PATH}?`),
        undefined,
        { revalidate: true }
      );
      await mutate(DEVICES_BY_SITE_ID_PATH(id));
      onClose();

      navigate(`gateways/${data.deviceId}`);
    }

    setIsUpdating(false);
  };

  const linkGatewayForm = (
    <form className="mb-2 mt-[35px] flex flex-col gap-[43px]">
      <Input
        id="serialNumber"
        label={t('literals.serialNumber')}
        value={serialNumber}
        onChange={event => serialNumberChangeHandler(event.target.value)}
        data-testid="serial-number-input"
      />
    </form>
  );

  return (
    <Dialog
      data-testid="link-tgp"
      header={t('link_gateway_form.header')}
      isOpen={showDialog}
      isLoading={isUpdating}
      isConfirmDisabled={!serialNumber || serialNumber === ''}
      confirmButton={t('link_gateway_form.link_button')}
      cancelButton={t_generic('literals.cancel')}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      onClose={handleClose}
      content={linkGatewayForm}
    />
  );
};
